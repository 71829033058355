import { onMounted, Ref, ref, useContext } from '@nuxtjs/composition-api'

export type GoogleMapsApi = typeof google.maps

export default function useGoogleMapsApi(): Ref<GoogleMapsApi | undefined> {
  const { $google } = useContext()

  const googleMapsApi = ref<GoogleMapsApi | undefined>()

  onMounted(async (): Promise<void> => {
    const { maps } = await $google
    googleMapsApi.value = maps
  })

  return googleMapsApi
}
