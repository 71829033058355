import { onBeforeMount, ref, Ref } from '@nuxtjs/composition-api'
import { GeolocationPositionErrorCode } from '~/types/app/Location'

interface IInput {
  beforeRequestCallback?: (showsBrowserPrompt: boolean) => void
  afterRequestCallback?: (showsBrowserPrompt: boolean) => void
}

interface IResult {
  isAvailable: Ref<boolean>
  hasCheckedPermissions: Ref<boolean>
  arePermissionsGiven: Ref<boolean>
  requestLocation: () => Promise<void>
  positionError: Ref<GeolocationPositionErrorCode | undefined>
  coordinates: Ref<GeolocationCoordinates | undefined>
}

const locationTimeout = 5000
let canPrompt = true

export default function useLocation({
  beforeRequestCallback,
  afterRequestCallback,
}: IInput = {}): IResult {
  const coordinates = ref<GeolocationCoordinates | undefined>()
  const isAvailable = ref<boolean>(
    process.client && 'geolocation' in window.navigator
  )
  const hasCheckedPermissions = ref<boolean>(false)
  const arePermissionsGiven = ref<boolean>(false)
  const positionError = ref<GeolocationPositionErrorCode>()

  function getPosition(
    options?: PositionOptions
  ): Promise<GeolocationPosition> {
    return new Promise((resolve, reject) =>
      window.navigator.geolocation.getCurrentPosition(resolve, reject, options)
    )
  }

  const requestLocation = async (): Promise<void> => {
    if (!isAvailable.value) return

    const showsBrowserPrompt = !arePermissionsGiven.value
    beforeRequestCallback && beforeRequestCallback(showsBrowserPrompt)

    try {
      const { coords } = await getPosition({
        enableHighAccuracy: true,
        timeout: locationTimeout,
      })
      arePermissionsGiven.value = true
      coordinates.value = coords
    } catch (error) {
      if (error instanceof GeolocationPositionError) {
        canPrompt = false
        positionError.value = error.code
      }

      isAvailable.value = false
    }

    afterRequestCallback && afterRequestCallback(showsBrowserPrompt)
  }

  async function checkPermissions(): Promise<boolean> {
    if (!canPrompt) {
      isAvailable.value = false
      return false
    }

    if (!window.navigator?.permissions?.query) {
      return false
    }

    const permissions = await window.navigator.permissions.query({
      name: 'geolocation',
    })

    switch (permissions.state) {
      case 'granted':
        return true
      case 'denied':
        isAvailable.value = false
        break
      case 'prompt':
        break
    }

    return false
  }

  onBeforeMount(async () => {
    arePermissionsGiven.value = await checkPermissions()
    hasCheckedPermissions.value = true

    if (arePermissionsGiven.value) {
      await requestLocation()
    }
  })

  return {
    isAvailable,
    hasCheckedPermissions,
    arePermissionsGiven,
    requestLocation,
    positionError,
    coordinates,
  }
}
