import { debounce } from 'lodash-es'
import {
  computed,
  Ref,
  ref,
  useContext,
  useRoute,
} from '@nuxtjs/composition-api'
import { IGoogleMapsAutoCompleteResults } from '~/types/app/Location'
import {
  getAddressComponent,
  setAddressComponent,
} from '~/lib/addresses-helpers'
import { AutocompleteResults } from '~/hooks/google/useGoogleMapsAutocompleteService'
import { Geocoder } from '~/hooks/google/useGoogleMapsGeocoder'
import {
  EComponentName,
  EScreenName,
  ETrackingType,
  getScreenName,
} from '~/lib/segment'
import { IAddressType } from '~/types/app/Tracking'

interface IInput {
  isLoading: Ref<boolean>
  searchInput: Ref<string>
  searchInputRef: Ref<HTMLInputElement | null>
  updateAutoCompletion: () => void
  isAutoCompleteFailed: Ref<boolean>
  autoCompleteResults: Ref<AutocompleteResults>
  geocoder: Ref<Geocoder>
  onGeocodeFailed: (place?: google.maps.GeocoderResult) => void
  onGeocodeSuccess: (place: google.maps.GeocoderResult) => void
}

interface IResult {
  onSearchInput: () => void
  isUpdatingSearch: Ref<boolean>
  isHouseNumberMissing: Ref<boolean>
  handleSelect: (result: IGoogleMapsAutoCompleteResults) => Promise<void>
}

function getHouseNumber(address: string): string | undefined {
  const match = address.match(/\d+(?:-\d+)?[a-z]?/)
  return match ? match[0] : undefined
}

function geocodeHouseNumberCheck(place: google.maps.GeocoderResult): boolean {
  const houseNumbers = getAddressComponent('street_number', place)
  if (!houseNumbers) return false

  return houseNumbers.length !== 0
}

export default function useLocationAutocomplete({
  isLoading,
  searchInput,
  searchInputRef,
  updateAutoCompletion,
  isAutoCompleteFailed,
  autoCompleteResults,
  geocoder,
  onGeocodeFailed: onGeocodeFailedInner,
  onGeocodeSuccess: onGeocodeSuccessInner,
}: IInput): IResult {
  const { $segmentEvent } = useContext()
  const route = useRoute()

  const isUpdatingSearch = ref<boolean>(false)
  const isHouseNumberMissing = ref<boolean>(false)
  const isGeocodeFailed = ref<boolean>(false)

  function reset() {
    searchInput.value = ''
    isAutoCompleteFailed.value = false
    autoCompleteResults.value = []
    isHouseNumberMissing.value = false
    isGeocodeFailed.value = false
  }

  async function updateSearchInput(): Promise<void> {
    isGeocodeFailed.value = false
    isAutoCompleteFailed.value = false
    isHouseNumberMissing.value = false
    await updateAutoCompletion()
    isUpdatingSearch.value = false
  }

  const debouncedUpdateSearchInput = computed(() =>
    debounce(updateSearchInput, 300)
  )

  function onSearchInput(): void {
    isUpdatingSearch.value = true
    debouncedUpdateSearchInput.value()
  }

  function onGeocodeFailed(place?: google.maps.GeocoderResult): void {
    isGeocodeFailed.value = true
    onGeocodeFailedInner(place)
  }

  function onGeocodeSuccess(place: google.maps.GeocoderResult): void {
    onGeocodeSuccessInner(place)
    reset()
  }

  async function handleSelect(
    result: IGoogleMapsAutoCompleteResults
  ): Promise<void> {
    const position = autoCompleteResults.value.indexOf(result) + 1

    await selectResult(result)

    trackSelection(position)
  }

  function isStreetAddressType(types: string[]) {
    return types.some((type) => ['street_address', 'route'].includes(type))
  }

  async function selectResult(
    result: IGoogleMapsAutoCompleteResults
  ): Promise<void> {
    isHouseNumberMissing.value = false
    const autocompleteHouseNumber = getHouseNumber(result.primaryLabel)
    if (isStreetAddressType(result.types) && !autocompleteHouseNumber) {
      searchInputRef.value?.focus()
      searchInput.value = result.primaryLabel + ' '
      isHouseNumberMissing.value = true
      return
    }

    if (!geocoder.value) return

    searchInput.value = result.primaryLabel

    isLoading.value = true
    const { results: geocoderResults } = await geocoder.value.geocode({
      placeId: result.placeId,
    })

    if (geocoderResults.length === 0) {
      isLoading.value = false
      onGeocodeFailed()
      return
    }

    const [place] = geocoderResults

    if (!geocodeHouseNumberCheck(place)) {
      isLoading.value = false
      isHouseNumberMissing.value = true
      return
    }

    const addressComponents = place.formatted_address.split(',')

    if (addressComponents.length === 0) {
      onGeocodeFailed(place)
      isLoading.value = false
    }

    // correct missing street number extensions
    let updatedPlace = place
    let geocodeStreet = addressComponents[0]

    if (!geocodeStreet.includes(autocompleteHouseNumber ?? '')) {
      updatedPlace = { ...place }
      setAddressComponent(
        autocompleteHouseNumber || '',
        'street_number',
        updatedPlace
      )

      const correctedStreet = `${getAddressComponent('route', updatedPlace)}${
        autocompleteHouseNumber ? ' ' + autocompleteHouseNumber : ''
      }`
      updatedPlace.formatted_address = updatedPlace.formatted_address.replace(
        geocodeStreet,
        correctedStreet
      )

      geocodeStreet = correctedStreet
    }

    // POST-1336 bugfix: when we are dealing with POI, the first part of conditional will pass.
    // We should not compare searchInpout with geocodeStreet as they will always be different.
    if (
      !isStreetAddressType(result.types) ||
      geocodeStreet.toLowerCase() === searchInput.value.toLowerCase()
    ) {
      onGeocodeSuccess(updatedPlace)
      result.geocodedAddress = updatedPlace.formatted_address
    } else {
      onGeocodeFailed(place)
    }

    isLoading.value = false
  }

  function trackSelection(position: number) {
    let componentContent
    if (isHouseNumberMissing.value) {
      componentContent = IAddressType.INCOMPLETE
    } else if (isGeocodeFailed.value) {
      componentContent = IAddressType.UNKNOWN
    } else {
      componentContent = IAddressType.GENERIC
    }

    $segmentEvent.TRACKING({
      trackingType: ETrackingType.click,
      screenName: EScreenName.addressSearch,
      componentName: EComponentName.addressSearchResult,
      componentContent,
      componentPosition: position,
      eventOrigin: getScreenName(route.value),
    })
  }

  return {
    onSearchInput,
    isUpdatingSearch,
    isHouseNumberMissing,
    handleSelect,
  }
}
