import { onMounted, ref, Ref, watch } from '@nuxtjs/composition-api'
import { GoogleMapsApi } from '~/hooks/google/useGoogleMapsApi'

export type Geocoder = google.maps.Geocoder | undefined

export default function useGoogleMapsGeocoder(
  googleMapsApi: Ref<GoogleMapsApi | undefined>
): Ref<Geocoder> {
  const geocoder = ref<google.maps.Geocoder | undefined>()

  const init = () => {
    if (googleMapsApi.value) {
      geocoder.value = new googleMapsApi.value.Geocoder()
    }
  }
  onMounted(init)
  watch(() => googleMapsApi.value, init)

  return geocoder
}
