import { Ref, ComputedRef } from '@nuxtjs/composition-api'
import { ELocationPickerState, IAddressBookAddress } from '~/types/app/Location'
import { EHubAvailabilityState } from '~/types/app/Hub'

export enum EOnboardingState {
  AddressInput = 'AddressInput',
  HubAvailabilityCheck = 'HubAvailabilityCheck',
  AutoComplete = 'autocomplete',
  Map = 'map',
  HubSuccess = 'hubsuccess',
  HubFailure = 'hubfailure',
  Loading = 'loading',
}

export type ELocationSelectionState =
  | EOnboardingState.HubAvailabilityCheck
  | EOnboardingState.AddressInput

export interface IOnboardingState {
  addressInputState: Ref<ELocationPickerState>
  hubAvailabilityState: Ref<EHubAvailabilityState>
  locationSelectionState: Ref<ELocationSelectionState>
  combinedOnboardingState: ComputedRef<EOnboardingState>
}

export interface ISelectedAddressBookAddress {
  address: IAddressBookAddress
  position: number
}

export interface IMapLoaded {
  map: Ref<google.maps.Map | undefined>
  hasUserSpecifiedEntrance: Ref<boolean> | undefined
}
