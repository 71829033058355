import { onMounted, Ref, ref, watch } from '@nuxtjs/composition-api'
import { IGoogleMapsAutoCompleteResults } from '~/types/app/Location'
import { GoogleMapsApi } from '~/hooks/google/useGoogleMapsApi'

export type AutocompleteResults = IGoogleMapsAutoCompleteResults[]

interface IResult {
  updateAutoCompletion: () => void
  isAutoCompleteFailed: Ref<boolean>
  results: Ref<AutocompleteResults>
}

export default function useGoogleMapsAutocompleteService(
  googleMapsApi: Ref<GoogleMapsApi | undefined>,
  searchInput: Ref<string>
): IResult {
  const autocompleteService = ref<
    google.maps.places.AutocompleteService | undefined
  >()
  const isAutoCompleteFailed = ref(false)
  const results = ref([]) as Ref<AutocompleteResults>

  function init() {
    if (googleMapsApi.value) {
      autocompleteService.value =
        new googleMapsApi.value.places.AutocompleteService()
    }
  }
  onMounted(init)
  watch(() => googleMapsApi.value, init)

  async function updateAutoCompletion(): Promise<void> {
    if (searchInput.value === '') {
      results.value = []
      return
    }

    if (!autocompleteService.value) return

    try {
      const { predictions = [] } =
        await autocompleteService.value.getPlacePredictions({
          input: searchInput.value,
        })

      results.value = predictions.map(
        (x: google.maps.places.AutocompletePrediction) => ({
          placeId: x.place_id,
          primaryLabel: x.structured_formatting.main_text,
          secondaryLabel: x.structured_formatting.secondary_text,
          geocodedAddress: '',
          types: x.types,
        })
      ) as IGoogleMapsAutoCompleteResults[]

      if (results.value.length === 0) {
        isAutoCompleteFailed.value = true
      }
    } catch (e) {
      isAutoCompleteFailed.value = true
    }
  }

  return {
    updateAutoCompletion,
    isAutoCompleteFailed,
    results,
  }
}
