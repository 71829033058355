/* eslint-disable camelcase */
import { IHub } from '~/types/app/Hub'
import { IDeliveryEstimate } from '~/api/services/get-delivery-estimate/get-delivery-estimate'
import { Ref } from '@nuxtjs/composition-api'

export enum EBuildingType {
  APARTMENT = 'apartment',
  HOUSE = 'house',
  OFFICE = 'office',
  OTHER = 'other',
}

export type BuildingType = EBuildingType | ''

export interface IAddressAdditionalData {
  tag?: string
  address_name?: string
  house_number?: string
  floor_number?: string
  name_on_doorbell?: string
  building_type?: BuildingType
  building_location?: string
  entrance?: string
  company_name?: string
  is_elevator_available?: boolean
}

export interface IAddress {
  address_id?: string
  id?: string
  city?: string
  company_name?: string
  country?: string
  country_code?: string
  first_name?: string
  last_name?: string
  phone?: string
  postal_code?: string
  street_address_1?: string
  street_address_2?: string
}

export interface IAddressCamelCase {
  addressId?: string
  id?: string
  city?: string
  companyName?: string
  country?: string
  countryCode?: string
  firstName?: string
  lastName?: string
  phone?: string
  postalCode?: string
  streetAddress1?: string
  streetAddress2?: string
}

export interface ICheckoutPayloadAddress {
  address_id?: string
  company_name?: string
  street_address_1?: string
  street_address_2?: string
  city?: string
  postal_code?: string
  country?: string
  phone?: string
}

export interface IAddressBookAddress {
  id: string
  latitude: number
  longitude: number
  street_address: string
  post_code: string
  city: string
  country_code: string
  comment: string
  is_default: boolean
}

export type AddressListItem = IAddressBookAddress & IAddressAdditionalData

export interface IAddressBookApiResponse {
  version: number
  address_list: AddressListItem[]
}

export interface ICoordinates {
  latitude: number
  longitude: number
}

export interface ILocationInput {
  geocodedAddress?: string
  userInput?: string
  coordinates?: ICoordinates
  address?: IAddress
}

export interface IGoogleMapsAutoCompleteResults {
  placeId: string
  primaryLabel: string
  secondaryLabel: string
  geocodedAddress: string
  types: string[]
}

export interface IZoomInfo {
  currentZoom: Ref<number>
  isSetByUser: Ref<boolean>
}

export interface ILocationPickerResult {
  coordinates: ICoordinates
  geocodedAddress: string
  userInput: string
  address?: IAddress
}

export interface IOnboardingResult {
  hub?: IHub
  deliveryEta?: IDeliveryEstimate
  coordinates: ICoordinates
  geocodedAddress: string
  address?: IAddress
}

type IOnboardingResultsRefs = Required<{
  [K in keyof IOnboardingResult]: Ref<IOnboardingResult[K]>
}>

export type IOnboardingInput = Omit<IOnboardingResultsRefs, 'coordinates'> & {
  coordinates: Ref<ICoordinates | undefined>
  userInput: Ref<string>
  addressAddition: Ref<string>
}

export enum ELocationPickerState {
  AutoComplete = 'autocomplete',
  Map = 'map',
  Success = 'success',
}

export enum GeolocationPositionErrorCode {
  PERMISSION_DENIED = 1,
  POSITION_UNAVAILABLE = 2,
  TIMEOUT = 3,
}
