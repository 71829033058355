import { computed, ref } from '@nuxtjs/composition-api'
import { ELocationPickerState, IOnboardingInput } from '~/types/app/Location'
import { EOnboardingState, IOnboardingState } from '~/types/app/Onboarding'
import { EHubAvailabilityState } from '~/types/app/Hub'

export const DEFAULT_ONBOARDING_STATE: IOnboardingState = {
  addressInputState: ref(ELocationPickerState.AutoComplete),
  hubAvailabilityState: ref(EHubAvailabilityState.loading),
  locationSelectionState: ref(EOnboardingState.AddressInput),
  combinedOnboardingState: computed(() => EOnboardingState.AutoComplete),
}

export const DEFAULT_ONBOARDING_INPUT: IOnboardingInput = {
  userInput: ref(''),
  hub: ref(),
  deliveryEta: ref(),
  coordinates: ref(),
  geocodedAddress: ref(''),
  address: ref(),
  addressAddition: ref(''),
}
