
















































































import { computed, inject, ref, useContext, useRoute, useStore, watch } from '@nuxtjs/composition-api';
import { cloneDeep } from 'lodash-es';
import { ELocationPickerState, IOnboardingInput } from '~/types/app/Location';
import PulseLoader from 'vue-spinner/src/PulseLoader.vue';
import { theme } from '~/tailwind.config';
import { getAddressComponent, geocodeLatLng } from '~/lib/addresses-helpers';
import useGoogleMapsAutocompleteService from '~/hooks/google/useGoogleMapsAutocompleteService';
import useGoogleMapsGeocoder from '~/hooks/google/useGoogleMapsGeocoder';
import useLocationAutocomplete from '~/hooks/onboarding/useLocationAutocomplete';
import useLocation from '~/hooks/location/useLocation';
import IRootState from '~/types/app/State';
import useGoogleMapsApi from '~/hooks/google/useGoogleMapsApi';
import { TranslateResult } from 'vue-i18n';
import { EComponentContent, EComponentName, EScreenName, ETrackingType, getScreenName } from '~/lib/segment';
import { IOnboardingState } from '~/types/app/Onboarding';
import { DEFAULT_ONBOARDING_INPUT, DEFAULT_ONBOARDING_STATE } from '~/components/onboarding/LocationSelection/provide';
interface IAutocompleError {
  title: TranslateResult;
  text: TranslateResult;
}
const __sfc_main = {};

__sfc_main.setup = (__props, __ctx) => {
  const PERMISSION_PROMPT_NAME = 'location_permission_prompt';
  const {
    i18n,
    $segmentEvent
  } = useContext();
  const store = useStore<IRootState>();
  const route = useRoute();
  const emit = __ctx.emit;
  const isLoading = ref<boolean>(false);
  const searchInputRef = ref<HTMLInputElement | null>(null);
  const {
    addressInputState
  } = inject<IOnboardingState>('onboardingState', DEFAULT_ONBOARDING_STATE);
  const {
    userInput,
    address,
    geocodedAddress,
    coordinates
  } = inject<IOnboardingInput>('onboardingInput', DEFAULT_ONBOARDING_INPUT);
  const googleMapsApi = useGoogleMapsApi();
  const geocoder = useGoogleMapsGeocoder(googleMapsApi);
  const {
    isAvailable: isLocationAvailable,
    hasCheckedPermissions: hasCheckedLocationPermissions,
    arePermissionsGiven: areLocationPermissionsGiven,
    requestLocation,
    positionError: locationPositionError,
    coordinates: locationCoordinates
  } = useLocation({
    beforeRequestCallback: (showsBrowserPrompt: boolean) => {
      if (!showsBrowserPrompt) {
        return;
      }

      $segmentEvent.TRACKING({
        trackingType: ETrackingType.nativeUiPromptShown,
        screenName: EScreenName.addressSearch,
        eventOrigin: getScreenName(route.value),
        componentName: PERMISSION_PROMPT_NAME
      });
    },
    afterRequestCallback: (showsBrowserPrompt: boolean) => {
      if (!showsBrowserPrompt) {
        return;
      }

      $segmentEvent.TRACKING({
        trackingType: ETrackingType.nativeUiResponseReceived,
        screenName: EScreenName.addressSearch,
        eventOrigin: getScreenName(route.value),
        componentName: PERMISSION_PROMPT_NAME,
        componentContent: isLocationAvailable.value ? EComponentContent.allow : EComponentContent.deny
      });
    }
  });
  watch([addressInputState, hasCheckedLocationPermissions], trackScreenViewed);

  function trackScreenViewed() {
    if (hasCheckedLocationPermissions.value && addressInputState.value === ELocationPickerState.AutoComplete) {
      $segmentEvent.TRACKING({
        trackingType: ETrackingType.screenViewed,
        screenName: EScreenName.addressSearch,
        componentName: areLocationPermissionsGiven.value ? EComponentName.currentLocationAllowed : EComponentName.currentLocationNotAllowed,
        eventOrigin: getScreenName(route.value)
      });
    }
  }

  async function onCurrentLocationUpdate() {
    if (!googleMapsApi.value || !geocoder.value || !locationCoordinates.value) return;
    const latLng = new googleMapsApi.value.LatLng({
      lat: locationCoordinates.value.latitude,
      lng: locationCoordinates.value.longitude
    });
    const geocodedLocation = await geocodeLatLng({
      geocoder: geocoder.value,
      location: latLng
    });
    await store.dispatch('setCurrentLocation', geocodedLocation);
  }

  watch([geocoder, locationCoordinates], onCurrentLocationUpdate);
  const {
    updateAutoCompletion,
    isAutoCompleteFailed,
    results: autoCompleteResults
  } = useGoogleMapsAutocompleteService(googleMapsApi, userInput);
  const {
    onSearchInput,
    isUpdatingSearch,
    isHouseNumberMissing,
    handleSelect
  } = useLocationAutocomplete({
    isLoading,
    searchInput: userInput,
    searchInputRef,
    updateAutoCompletion,
    isAutoCompleteFailed,
    autoCompleteResults,
    geocoder,
    onGeocodeFailed: (place?: google.maps.GeocoderResult) => onSelectResult(false, place),
    onGeocodeSuccess: (place: google.maps.GeocoderResult) => onSelectResult(true, place)
  });
  const errorMessage = computed<IAutocompleError | null>(() => {
    if (isLoading.value || isUpdatingSearch.value || isHouseNumberMissing.value) {
      return null;
    }

    if (userInput.value.length > 0 && autoCompleteResults.value.length === 0) {
      return {
        title: i18n.t('address_search_list_hint_title_no_results', {
          query: userInput.value
        }),
        text: i18n.t('address_search_list_hint_text_no_results')
      };
    }

    if (userInput.value.length === 0 && !!locationPositionError.value) {
      return {
        title: i18n.t('address_search_list_hint_title_no_user_location'),
        text: i18n.t('address_search_list_hint_text_no_results_no_user_location')
      };
    }

    return null;
  });

  function onRequestLocation() {
    trackCurrentLocationClicked();
    requestLocation();
  }

  function onSelectCurrentLocation() {
    trackCurrentLocationClicked();

    if (store.state.currentLocation) {
      const clonedLocation = cloneDeep(store.state.currentLocation);
      coordinates.value = clonedLocation.coordinates;
      address.value = clonedLocation.address;
      geocodedAddress.value = clonedLocation.geocodedAddress ?? '';
    }

    emit('result-chosen');
  }

  function trackCurrentLocationClicked() {
    $segmentEvent.TRACKING({
      trackingType: ETrackingType.click,
      screenName: EScreenName.addressSearch,
      componentName: EComponentName.currentLocation,
      componentContent: areLocationPermissionsGiven.value ? EComponentContent.address : EComponentContent.locationPermission,
      eventOrigin: getScreenName(route.value)
    });
  }

  function onSelectResult(isSuccess: boolean, place?: google.maps.GeocoderResult): void {
    const street = getAddressComponent('route', place);
    const streetNumber = getAddressComponent('street_number', place);
    coordinates.value = isSuccess && !!place ? {
      latitude: place.geometry.location.lat(),
      longitude: place.geometry.location.lng()
    } : undefined;
    address.value = {
      city: getAddressComponent('locality', place),
      country: getAddressComponent('country', place),
      country_code: getAddressComponent('country', place, true),
      postal_code: getAddressComponent('postal_code', place),
      street_address_1: street ? `${street} ${streetNumber}` : ''
    };
    geocodedAddress.value = place ? place.formatted_address : '';
    emit('result-chosen');
  }

  return {
    theme,
    store,
    emit,
    isLoading,
    searchInputRef,
    userInput,
    isLocationAvailable,
    autoCompleteResults,
    onSearchInput,
    isHouseNumberMissing,
    handleSelect,
    errorMessage,
    onRequestLocation,
    onSelectCurrentLocation
  };
};

__sfc_main.components = Object.assign({
  PulseLoader
}, __sfc_main.components);
export default __sfc_main;
